@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap";
* {
  scroll-behavior: smooth;
  color: #fff;
  font-family: IBM Plex Mono, monospace;
  font-size: 2vh;
  overflow: hidden;
}

@media (width <= 812px) {
  * {
    font-size: 2.5vh;
    line-height: 3vh;
  }
}

* body {
  background: #000;
  margin: 0;
}

* ::selection {
  background: red;
}

@media (width <= 812px) {
  .nav-desktop {
    display: none;
  }
}

@media (width >= 812px) {
  .nav-mobile {
    display: none;
  }
}

#photo {
  width: 100vw;
  height: 100vh;
  font-size: 1vh;
}

.h-red {
  color: #000;
  background: red;
}

.h-green {
  color: #000;
  background: #0f0;
}

.h-yellow {
  color: #000;
  background: #ff0;
  text-decoration: underline;
}

.h-blue {
  background: #00f;
  text-decoration: underline;
}

nav {
  background: #000;
  border-right: 1px solid #fff;
  height: 100vh;
  padding: 3vh 1vw 0;
  overflow-x: hidden;
}

@media (width <= 812px) {
  nav {
    text-align: center;
    width: 100vw;
    left: 0;
  }
}

@media (width >= 812px) {
  nav {
    z-index: 100;
    width: 25vw;
    display: inline-block;
    position: absolute;
  }

  nav .bottom {
    background: #000;
    position: absolute;
    bottom: 5vh;
  }
}

nav a {
  cursor: pointer;
  text-decoration: underline;
}

nav .title {
  text-align: center;
  color: #000;
  background-color: #0f0;
}

nav .leave {
  background: #000;
  flex-direction: column;
  display: flex;
}

nav .leave .leave {
  flex-direction: row;
}

nav .bottom a {
  -webkit-text-decoration: under;
  text-decoration: under;
  padding: 0 10px;
}

main {
  flex-wrap: wrap;
  width: 500vw;
  display: flex;
}

@media (width <= 812px) {
  main .go-home {
    text-align: center;
    text-align: center;
    color: #000;
    z-index: 1000;
    z-index: 10000;
    background-color: #0f0;
    width: 100vw;
    height: 3vh;
    margin-bottom: 3vh;
    display: inline-block;
    position: relative;
    top: 8vh;
  }
}

@media (width >= 812px) {
  main .go-home {
    display: none;
  }
}

main .screen {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 1vw 0 0;
  overflow: hidden visible;
}

main .screen ::-webkit-scrollbar {
  width: 8px;
}

main .screen ::-webkit-scrollbar-thumb {
  background-color: #000;
  border: 1px solid #fff;
}

main .screen .selector {
  background-color: #000;
  border: 1px solid #fff;
  scrollbar-color: black !important;
}

@media (width >= 812px) {
  main .screen .content {
    margin-top: 8vh;
    padding-left: 30vw;
    padding-right: 5vw;
  }
}

@media (width <= 812px) {
  main .screen .content {
    padding: 4vh 4vw 0;
  }

  main .screen .videos {
    flex-wrap: wrap;
  }
}

main .screen .screen-title {
  background-color: #00f;
  text-decoration: underline;
}

main .screen a {
  cursor: pointer;
}

main #title {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2vh;
  display: flex;
}

@media (width <= 812px) {
  main #title .home-title {
    display: none;
  }

  main #title .content {
    height: 80vh;
    margin-top: 15vh;
  }
}

@media (width <= 812px) and (width <= 812px) {
  main #title .content {
    margin-top: 7vh;
  }
}

@media (width <= 812px) {
  main #title .content span {
    color: #fff;
    width: 100vw;
  }
}

main #about-me, main #phd, main #smc, main #sound {
  line-height: 2.5vh;
}

main #about-me div, main #phd div, main #smc div, main #sound div {
  margin: 2vh 0;
}

main #about-me .videos, main #phd .videos, main #smc .videos, main #sound .videos {
  display: flex;
}

main #smc a {
  text-decoration: underline;
}
/*# sourceMappingURL=index.880b5d3a.css.map */
