// font
//@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
// vars

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');

$ipad: 812px;
$phone: 430px;
$rot: 480px;
$tgreen: rgb(0, 255, 0);
$screen-num: 5;

@mixin green-title {
	background-color: $tgreen;
	text-align: center;
	color: black;
}
// styles
* {
	scroll-behavior: smooth;
	color: white;
	font-family: 'IBM Plex Mono', monospace;
	font-size: 2vh;

	@media (max-width: $ipad) {
		font-size: 2.5vh;
		line-height: 3vh;
	}

	overflow: hidden;
	body {
		background: black;
		margin: 0;
	}

	// selection color
	::-moz-selection {
		background: red;
	}
	::selection {
		background: red;
	}
}

@media (max-width: $ipad) {
	.nav-desktop {
		display: none;
	}
}
@media (min-width: $ipad) {
	// in mobile nav is part of screen title
	.nav-mobile {
		display: none;
	}
}

#photo {
	width: 100vw;
	height: 100vh;
	font-size: 1vh;
}
.h-red {
	background: red;
	color: black;
}
.h-green {
	background: $tgreen;
	color: black;
}
.h-yellow {
	background: yellow;
	color: black;
	text-decoration: underline;
}
.h-blue {
	background: blue;
	text-decoration: underline;
}

nav {
	height: 100vh;
	padding: 3vh 1vw;
	padding-bottom: 0;
	border-right: 1px solid white;
	background: black;
	overflow-x: hidden;

	@media (max-width: $ipad) {
		width: 100vw;
		left: 0;
		text-align: center;
	}
	@media (min-width: $ipad) {
		position: absolute;
		display: inline-block;
		z-index: 100;
		width: 25vw;
		.bottom {
			background: black;
			position: absolute;
			bottom: 5vh;
		}
	}

	a {
		cursor: pointer;
		text-decoration: underline;
	}

	.title {
		@include green-title();
	}

	.leave {
		// display: none;
		background: black;
		display: flex;
		flex-direction: column;
		.leave {
			flex-direction: row;
		}
	}
	.bottom {
		a {
			padding: 0 10px;
			text-decoration: under;
		}
	}
}
main {
	display: flex;
	flex-wrap: wrap;
	width: $screen-num * 100vw;
	@media (max-width: $ipad) {
		.go-home {
			text-align: center;
			position: relative;
			top: 8vh;
			width: 100vw;
			@include green-title();
			z-index: 1000;
			display: inline-block;
			height: 3vh;
			margin-bottom: 3vh;
			z-index: 10000;
		}
	}
	@media (min-width: $ipad) {
		.go-home {
			display: none;
		}
	}
	.screen {
		margin: 0;
		padding: 0;
		padding-top: 1vw;
		height: 100vh;
		width: 100vw;
		overflow-x: hidden;
		// scrollbar
		overflow-y: visible;
		/* Chrome, safari */
		::-webkit-scrollbar {
			width: 8px;
		}
		::-webkit-scrollbar-thumb {
			background-color: black;
			border: 1px solid white;
		}

		/* Firefox */
		.selector {
			scrollbar-color: black !important;
			background-color: black;
			border: 1px solid white;
		}

		@media (min-width: $ipad) {
			.content {
				margin-top: 8vh;
				padding-left: 30vw;
				padding-right: 5vw;
			}
		}
		@media (max-width: $ipad) {
			.content {
				padding: 0 4vw;
				padding-top: 4vh;
			}
			.videos {
				flex-wrap: wrap;
			}
		}

		.screen-title {
			background-color: blue;
			text-decoration: underline;
		}

		a {
			cursor: pointer;
		}
	}
	#title {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		line-height: 2vh;

		@media (max-width: $ipad) {
			.home-title {
				display: none;
			}
			.content {
				height: 80vh;
				margin-top: 15vh;

				@media (max-width: $ipad) {
					margin-top: 7vh;
				}

				span {
					width: 100vw;
					color: white;
				}
			}
		}
	}
	#about-me,
	#phd,
	#smc,
	#sound {
		line-height: 2.5vh;
		div {
			margin: 2vh 0;
		}
		.videos {
			display: flex;
		}
	}

	#smc {
		a {
			text-decoration: underline;
		}
	}
}

// .title-name {
// 	user-select: none;
// 	font-family: monospace;
// 	overflow: hidden;
// 	display: flex;

// 	// todo: fix rotated phones
// 	@media (min-width: $ipad) {
// 		font-size: 1.5vw;
// 	}
// 	@media (max-width: $ipad) {
// 		font-size: min(10pt, 3vw);
// 		flex-direction: column;
// 	}

// 	.color1 {
// 		color: greenyellow;
// 	}
// 	.color2 {
// 		color: green;
// 	}
// }

/*.infos {
	margin-top: 1.5vh;
	color: green;
	user-select: none;
	span {
		color: greenyellow;
	}
	@media (min-width: $ipad) {
		font-size: 1.5vw;
	}
	@media (max-width: $ipad) {
		font-size: min(12pt, 3.8vw);
		padding: 0 3vw;
	}
}*/
